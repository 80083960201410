import {
  SCAN_BARCODE_ORDER,
  SCAN_BARCODE_GROUP,
  SCAN_DETAIL_ORDER,
  SCAN_DETAIL_GROUP,
} from "../domain/scannerSlice";

export class EventHandlers {
  static handleScanOrder = ({ dispatch, modal }) => {
    dispatch(SCAN_BARCODE_ORDER(modal));
  };
  static handleScanGroup = ({ dispatch, modal }) => {
    dispatch(SCAN_BARCODE_GROUP(modal));
  };
  static handleDetailOrder = ({ dispatch, modal }) => {
    dispatch(SCAN_DETAIL_ORDER(modal));
  };
  static handleDetailGroup = ({ dispatch, modal }) => {
    dispatch(SCAN_DETAIL_GROUP(modal));
  };
}
