import React, { useCallback, useEffect, useRef, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Api, EventHandlers } from "../repository";
import Barcode from "../../../assets/scan/gx2MOoN1Ep.gif";
import { DetailGroupScanLayout } from "../../../components/Layout/Content/Scanner";
import { BlankDialog } from "../../../components/Layout/Content";

const ScanBarcodeGroup = ({ form }) => {
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const { socket } = useSelector((state) => state.data);
  const { scanGroup, detailGroup } = useSelector((state) => state.scanner);
  const { data, isLoading, isError } = Api.detailGroup({
    id: detailGroup.id,
  });

  const [dataGroup, setDataGroup] = useState({
    nota: null,
    keterangan: null,
    antrian: 0,
    size: 0,
    additional: 0,
    status: { id: null, nama: "", warna: "white" },
    list: [],
    produksi: [],
  });

  const [focused, setFocused] = useState(false);
  const inputRef = useRef(null);

  const handleClose = () => {
    EventHandlers.handleScanGroup({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
      },
    });
  };

  const handleDetailGroup = ({ nota }) => {
    handleClose();
    EventHandlers.handleDetailGroup({
      dispatch,
      modal: {
        id: nota,
        open: true,
        title: "Detail Group !",
      },
    });
  };

  const handleCloseDetailGroup = useCallback(() => {
    form.reset();
    EventHandlers.handleDetailGroup({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
      },
    });
    EventHandlers.handleScanGroup({
      dispatch,
      modal: {
        id: null,
        open: true,
        title: "Scan !",
        ket: "Scan QR-Code Pada Nota Group !",
      },
    });
  }, [dispatch, form]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.isTrusted && !focused && scanGroup.open) {
        inputRef.current.select();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [focused, scanGroup.open]);

  const resetTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(null);
    setStartTime(null);
    if (inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.blur();
    }
  };

  useEffect(() => {
    if (scanGroup.open) {
      setTimeout(() => {
        inputRef.current.select();
      }, 100);
    }
  }, [scanGroup.open]);

  const handleChange = (event) => {
    const { value } = event.target;

    if (!startTime) {
      setStartTime(Date.now());
    }

    if (value.length >= 32) {
      handleDetailGroup({ nota: value });
      resetTimer();
    }

    if (timer) {
      clearTimeout(timer);
    }

    setTimer(
      setTimeout(() => {
        resetTimer();
      }, 400)
    );
  };

  useEffect(() => {
    if (isError) {
      handleCloseDetailGroup();
    }
  }, [handleCloseDetailGroup, isError]);

  useEffect(() => {
    if (data) {
      setDataGroup({
        nota: data.data.nota,
        keterangan: data.data.keterangan,
        antrian: data.data.group.nota.length,
        size: data.data.group.size.reduce(
          (acc, currentValue) => acc + currentValue.qty,
          0
        ),
        additional: data.data.group.additional.reduce(
          (acc, currentValue) => acc + currentValue.qty,
          0
        ),
        status: {
          id: data.data.status.id,
          nama: data.data.status.status,
          warna: data.data.status.warna,
        },
        list: data.data.group.nota,
        produksi: data.data.produksi,
      });
    }
  }, [data]);

  const {
    mutate: mutateProgressStatusProduksiGroup,
    isPending: isLoadingProgressProduksiGroup,
  } = Api.progressAntrainGroup({
    handleClose: handleCloseDetailGroup,
    socket,
    dispatch,
  });

  const { mutate: mutateProduksiGroup, isPending: isLoadingProduksiGroup } =
    Api.produksiAntrainGroup({
      handleClose: handleCloseDetailGroup,
      socket,
      dispatch,
    });

  const onSubmitProduksi = (values) => {
    mutateProduksiGroup({ id: data.data.id, body: values });
  };

  const onSubmitProgress = (values) => {
    mutateProgressStatusProduksiGroup({ id: data.data.id, body: values });
  };

  return (
    <>
      <BlankDialog
        maxWidth={"xs"}
        open={scanGroup.open}
        handleClose={handleClose}
        title={scanGroup.title}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          paddingY={2}
        >
          <img
            src={Barcode}
            alt="Barcode"
            style={{ width: 120, marginBottom: "1rem" }}
          />
          <Typography fontWeight={700} color={"#797979"}>
            {scanGroup.ket}
          </Typography>
          <input
            ref={inputRef}
            autoComplete="off"
            className="hide-input"
            onChange={handleChange}
            onBlur={() => setFocused(false)}
            onFocus={(event) => {
              event.target.select();
              setFocused(true);
            }}
            disabled={isLoading ? true : false}
          />
        </Stack>
      </BlankDialog>
      <DetailGroupScanLayout
        form={form}
        handleClose={handleCloseDetailGroup}
        data={dataGroup}
        isLoadingData={isLoading}
        isLoadingSubmit={
          isLoadingProgressProduksiGroup || isLoadingProduksiGroup
        }
        onSubmitProduksi={form.handleSubmit(onSubmitProduksi)}
        onSubmitProgress={form.handleSubmit(onSubmitProgress)}
      />
    </>
  );
};

export default ScanBarcodeGroup;
