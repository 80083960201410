import { useFetchingData, useUpdatingData } from "../../../hooks";
import { EventHandlers as EventHandlersAntrian } from "../../antrian/nota/repository";
import { EventHandlers as EventHandlersAntrianGroup } from "../../antrian/group/repository";
import { EventHandlers as EventHandlersDikerjakan } from "../../dikerjakan/nota/repository";
import { EventHandlers as EventHandlersDikirim } from "../../checkout/dikirim/repository";
import { EventHandlers as EventHandlersSiapKirim } from "../../checkout/siapkirim/repository";
import { EventHandlers as EventHandlersGroup } from "../../dikerjakan/group/repository";
export class Api {
  static detailAntrian = ({ id }) => {
    return useFetchingData({
      endpoint: `antrian/scan/${id ? id : ""}`,
      enabled: id ? true : false,
      gcTime: 0,
    });
  };

  static detailGroup = ({ id }) => {
    return useFetchingData({
      endpoint: `group/scan/${id ? id : ""}`,
      enabled: id ? true : false,
      gcTime: 0,
    });
  };

  static ambilAntrian = ({ handleClose, socket, dispatch }) => {
    return useUpdatingData({
      endpoint: `antrian/ambil`,
      invalidated: [`antrian`, "dikerjakan"],
      onSuccess: () => {
        EventHandlersAntrian.handleRefetchStatus({
          dispatch,
          refetch_status: true,
        });
        handleClose();
        if (socket) {
          socket.emit("antrian");
          socket.emit("antrian_group");
        }
      },
    });
  };

  static progressAntrianProduksi = ({ handleClose, dispatch, socket }) => {
    return useUpdatingData({
      endpoint: `dikerjakan/progress/produksi`,
      invalidated: [
        `dikerjakan`,
        `group/dikerjakan`,
        `antrian`,
        `group/antrian`,
      ],
      onSuccess: () => {
        EventHandlersDikerjakan.handleRefetchStatus({
          dispatch,
          refetch_status: true,
        });
        handleClose();
        if (socket) {
          socket.emit("antrian");
          socket.emit("antrian_group");
        }
      },
    });
  };

  static checkoutKirim = ({ handleClose, dispatch, socket }) => {
    return useUpdatingData({
      endpoint: `checkout/kirim`,
      invalidated: [`checkout/siap_kirim`, `checkout/dikirim`],
      onSuccess: () => {
        EventHandlersSiapKirim.handleRefetchStatus({
          dispatch,
          refetch_status: true,
        });
        EventHandlersDikirim.handleRefetchStatus({
          dispatch,
          refetch_status: true,
        });
        handleClose();
        if (socket) {
          socket.emit("checkout_siap_kirim");
          socket.emit("checkout_dikirim");
        }
      },
    });
  };

  static progressAntrainGroup = ({ handleClose, dispatch, socket }) => {
    return useUpdatingData({
      endpoint: `group/dikerjakan/progress`,
      invalidated: [
        `group/dikerjakan`,
        `dikerjakan`,
        `antrian`,
        `group/antrian`,
      ],
      onSuccess: () => {
        EventHandlersGroup.handleRefetchStatus({
          dispatch,
          refetch_status: true,
        });
        handleClose();
        if (socket) {
          socket.emit("antrian");
          socket.emit("antrian_group");
        }
      },
      onError: () => {
        return;
      },
    });
  };

  static produksiAntrainGroup = ({ handleClose, dispatch, socket }) => {
    return useUpdatingData({
      endpoint: `group/produksi`,
      invalidated: [
        `group/antrian`,
        `antrian`,
        `group/dikerjakan`,
        `dikerjakan`,
      ],
      onSuccess: () => {
        EventHandlersAntrianGroup.handleRefetchStatus({
          dispatch,
          refetch_status: true,
        });
        EventHandlersAntrian.handleRefetchStatus({
          dispatch,
          refetch_status: true,
        });
        handleClose();
        if (socket) {
          socket.emit("antrian");
          socket.emit("antrian_group");
          socket.emit("dikerjakan_group");
        }
      },
      onError: () => {
        return;
      },
    });
  };
}
