import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Divider, Chip, Paper } from "@mui/material";
import BlankDialog from "../BlankDialog";
import {
  CheckboxInput,
  CustomButton,
  dateFormat,
  Input,
  Select,
} from "../../../controls";
import { ActionGrid, ChipItems } from "../../../common";
import { useSelector } from "react-redux";
import TabelAntrianGroup from "../DataGroup/TabelAntrianGroup";
import { useGetCachedData } from "../../../../hooks";

// column antrian
const totalAntrian = [
  {
    field: "no",
    headerName: "No",
    flex: 1,
    type: "actions",
    filterable: false,
    minWidth: 50,
    maxWidth: 50,
    display: "flex",
    renderHeader: () => <strong style={{ textAlign: "center" }}>No</strong>,
    renderCell: (params) => (
      <Typography textAlign={"center"}>{params.value} </Typography>
    ),
  },
  {
    field: "nota",
    flex: 1,
    minWidth: 100,
    hideable: false,
    headerName: "No. Nota",
    display: "flex",
    renderHeader: () => <strong>No. Nota</strong>,
    renderCell: (params) => {
      return (
        <span
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value}
        </span>
      );
    },
  },
  {
    field: "check_in",
    type: "date",
    flex: 1,
    minWidth: 100,
    headerName: "Check-In",
    display: "flex",
    valueGetter: (value) => new Date(value),
    renderCell: (params) => {
      if (params.value instanceof Date && !isNaN(params.value)) {
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {dateFormat(params.value)}
          </span>
        );
      }
      return "";
    },
  },
  {
    field: "nama",
    flex: 1,
    sortable: false,
    minWidth: 200,
    display: "flex",
    renderHeader: () => <strong>Leads / Nama</strong>,
    renderCell: (params) => {
      return (
        <span
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value}
        </span>
      );
    },
  },
  {
    field: "status",
    headerName: "Progress",
    minWidth: 110,
    renderHeader: () => <strong>Progress</strong>,
    valueGetter: (value) => value.nama,
    renderCell: (params) => {
      return (
        <ChipItems
          size="small"
          label={params.value}
          backgroundcolor={params.row.status.warna}
          borderradius={"3px"}
          sx={{ height: "20px" }}
        />
      );
    },
  },
];

const DetailGroupScanLayout = ({
  form,
  handleClose,
  data,
  isLoadingData,
  isLoadingSubmit,
  onSubmitProduksi,
  onSubmitProgress,
}) => {
  const { role, user_id } = useSelector((state) => state.auth);
  const { detailGroup } = useSelector((state) => state.scanner);
  const [listStatus, setListStatus] = useState([]);

  const getDataStatus = useGetCachedData({ queryKey: "status" });

  const statusBaruMasuk = getDataStatus
    ? getDataStatus.data.default.find((status) => status.kode === "S01")
    : {};

  const statusDesain = getDataStatus
    ? getDataStatus.data.default.find((status) => status.kode === "S02")
    : {};

  const statusSiapProduksi = getDataStatus
    ? getDataStatus.data.default.find((status) => status.kode === "S03")
    : {};

  const statusSiapKirim = getDataStatus
    ? getDataStatus.data.default.find((status) => status.kode === "S05")
    : {};

  const statusPengiriman = getDataStatus
    ? getDataStatus.data.default.find((status) => status.kode === "S06")
    : {};

  const statusSelesai = getDataStatus
    ? getDataStatus.data.default.find((status) => status.kode === "S07")
    : {};

  const statusRetur = getDataStatus
    ? getDataStatus.data.default.find((status) => status.kode === "S08")
    : {};

  useEffect(() => {
    if (getDataStatus) {
      setListStatus(
        getDataStatus.data.produksi
          .filter((status) => status.status)
          .map((status) => ({
            id: status.id,
            name: status.nama,
            kode: status.kode,
          }))
      );
    }
  }, [getDataStatus]);

  const rowsAntrianData = data.list.map((row, index) => ({
    ...row,
    no: index + 1,
  }));

  const ProgressComponent = ({ titleButton = "Simpan", onSubmit }) => {
    return (
      <form onSubmit={onSubmit}>
        <Grid container spacing={2} marginTop={0}>
          <Grid item xs={12}>
            <Input
              name="keterangan"
              control={form.control}
              label="Keterangan"
              rules={{ required: false }}
              defaultValue=""
              rows={5}
            />
          </Grid>
        </Grid>

        {/* Validation Check */}
        <Grid
          container
          justifyContent={"end"}
          alignItems={"center"}
          marginTop={1}
          marginBottom={"-1rem"}
        >
          <CheckboxInput
            name={"confirmation"}
            control={form.control}
            label={"Pastikan data sudah benar!"}
            rules={{ required: false }}
            defaultValue={false}
          />
        </Grid>

        {/* Action Button */}
        <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
        <ActionGrid
          isLoading={isLoadingSubmit || isLoadingData}
          actionStart={
            <CustomButton
              loading={isLoadingSubmit || isLoadingData}
              onClick={handleClose}
              warna={"#A4A0A0"}
              widthbtn={"100%"}
            >
              Kembali
            </CustomButton>
          }
          actionEnd={
            <CustomButton
              loading={isLoadingSubmit || isLoadingData}
              warna={"next"}
              widthbtn={"100%"}
              disabled={form.watch("confirmation") ? false : true}
              type="submit"
            >
              {titleButton}
            </CustomButton>
          }
        />
      </form>
    );
  };

  const ProduksiComponent = ({ onSubmit }) => {
    return (
      <form onSubmit={onSubmit}>
        <Grid container spacing={2} marginTop={0}>
          <Grid item xs={6}>
            <Chip
              label={data.status.nama ?? "-"}
              sx={{
                backgroundColor: data.status.warna ?? "#000",
                color: "white",
                borderRadius: "5px",
                fontWeight: 500,
                width: "100%",
                height: "100%",
              }}
              variant="filled"
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              disabled={listStatus.length ? false : true}
              name={"status_id"}
              control={form.control}
              label={"Status"}
              options={
                data?.progress?.status?.kode
                  ? listStatus.filter(
                      (status) => status.kode !== data?.progress?.status?.kode
                    )
                  : listStatus
              }
              rules={{ required: "Status Harus Dipilih!" }}
              defaultValue={""}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="keterangan"
              control={form.control}
              label="Keterangan"
              rules={{ required: false }}
              defaultValue=""
              rows={5}
            />
          </Grid>
        </Grid>

        {/* Validation Check */}
        <Grid
          container
          justifyContent={"end"}
          alignItems={"center"}
          marginTop={1}
          marginBottom={"-1rem"}
        >
          <CheckboxInput
            name={"confirmation"}
            control={form.control}
            label={"Pastikan data sudah benar!"}
            rules={{ required: false }}
            defaultValue={false}
          />
        </Grid>

        {/* Action Button */}
        <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
        <ActionGrid
          isLoading={isLoadingSubmit || isLoadingData}
          actionStart={
            <CustomButton
              loading={isLoadingSubmit || isLoadingData}
              onClick={handleClose}
              warna={"#A4A0A0"}
              widthbtn={"100%"}
            >
              Kembali
            </CustomButton>
          }
          actionEnd={
            <CustomButton
              loading={isLoadingSubmit || isLoadingData}
              warna={"next"}
              widthbtn={"100%"}
              disabled={form.watch("confirmation") ? false : true}
              type="submit"
            >
              Update
            </CustomButton>
          }
        />
      </form>
    );
  };

  const CommonComponent = () => {
    return (
      <>
        <Divider sx={{ marginY: 2, marginX: "-1rem" }} />

        <Grid container spacing={2} justifyContent={"end"}>
          <Grid item xs={12}>
            <CustomButton
              loading={isLoadingSubmit || isLoadingData}
              onClick={handleClose}
              widthbtn={"100%"}
            >
              Kembali
            </CustomButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const RoleBasedComponent = () => {
    if (role === "admin_produksi") {
      if (parseInt(data.status.id) === statusSiapProduksi.id) {
        return (
          <ProgressComponent
            titleButton="Produksi"
            onSubmit={onSubmitProduksi}
          />
        );
      }
      if (
        ![
          statusBaruMasuk.id,
          statusDesain.id,
          statusSiapProduksi.id,
          statusSiapKirim.id,
          statusPengiriman.id,
          statusSelesai.id,
          statusRetur.id,
        ].includes(data.status.id) &&
        data.produksi.some((user) => user.id === user_id)
      ) {
        return <ProduksiComponent onSubmit={onSubmitProgress} />;
      }
    }

    return <CommonComponent />;
  };

  return (
    <BlankDialog
      open={detailGroup.open}
      handleClose={handleClose}
      title={detailGroup.title}
      isLoading={isLoadingSubmit || isLoadingData}
      outsideClose={false}
    >
      <Box>
        <Paper
          elevation={0}
          sx={{
            backgroundColor: "#E0F0F9",
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Grid container spacing={2}>
            <Grid container item xs={7}>
              <Grid item xs={5} fontWeight={700}>
                No. Faktur
              </Grid>
              <Grid item xs={0.5} fontWeight={700}>
                :
              </Grid>
              <Grid item xs={6.5} fontWeight={700}>
                {data.nota}
              </Grid>
              <Grid item xs={5}>
                Total Antrian
              </Grid>
              <Grid item xs={0.5}>
                :
              </Grid>
              <Grid item xs={6.5}>
                {data.antrian} Nota
              </Grid>
            </Grid>
            <Grid container item xs={5}>
              <Grid item xs={8.5}>
                Total Order
              </Grid>
              <Grid item xs={0.5}>
                :
              </Grid>
              <Grid item xs={3}>
                {data.size} Pcs
              </Grid>
              <Grid item xs={8.5}>
                Total Tambahan
              </Grid>
              <Grid item xs={0.5}>
                :
              </Grid>
              <Grid item xs={3}>
                {data.additional} Pcs
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Box>
        <TabelAntrianGroup rows={rowsAntrianData} column={totalAntrian} />
      </Box>
      <RoleBasedComponent />
    </BlankDialog>
  );
};

export default DetailGroupScanLayout;
