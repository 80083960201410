import React, { useCallback, useEffect, useRef, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Api, EventHandlers } from "../repository";
import Barcode from "../../../assets/scan/gx2MOoN1Ep.gif";
import { DetailOrderScanLayout } from "../../../components/Layout/Content/Scanner";
import { BlankDialog } from "../../../components/Layout/Content";

const ScanBarcodeOrder = ({ form }) => {
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const { socket } = useSelector((state) => state.data);
  const { scanOrder, detailOrder } = useSelector((state) => state.scanner);
  const { data, isLoading, isError } = Api.detailAntrian({
    id: detailOrder.id,
  });

  const [focused, setFocused] = useState(false);
  const inputRef = useRef(null);

  const handleClose = () => {
    EventHandlers.handleScanOrder({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
      },
    });
  };

  const handleDetailOrder = ({ nota }) => {
    handleClose();
    EventHandlers.handleDetailOrder({
      dispatch,
      modal: {
        id: nota,
        open: true,
        title: "Detail Order !",
      },
    });
  };

  const handleCloseDetailOrder = useCallback(() => {
    form.reset();
    EventHandlers.handleDetailOrder({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
      },
    });
    EventHandlers.handleScanOrder({
      dispatch,
      modal: {
        id: null,
        open: true,
        title: "Scan !",
        ket: "Scan QR-Code Pada Nota Order !",
      },
    });
  }, [dispatch, form]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.isTrusted && !focused && scanOrder.open) {
        inputRef.current.select();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [focused, scanOrder.open]);

  const resetTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(null);
    setStartTime(null);
    if (inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.blur();
    }
  };

  useEffect(() => {
    if (scanOrder.open) {
      setTimeout(() => {
        inputRef.current.select();
      }, 100);
    }
  }, [scanOrder.open]);

  const handleChange = (event) => {
    const { value } = event.target;

    if (!startTime) {
      setStartTime(Date.now());
    }

    if (value.length >= 32) {
      handleDetailOrder({ nota: value });
      resetTimer();
    }

    if (timer) {
      clearTimeout(timer);
    }

    setTimer(
      setTimeout(() => {
        resetTimer();
      }, 400)
    );
  };

  useEffect(() => {
    if (isError) {
      handleCloseDetailOrder();
    }
  }, [handleCloseDetailOrder, isError]);

  const { mutate: mutateAmbilAntrian, isPending: isLoadingDesain } =
    Api.ambilAntrian({
      handleClose: handleCloseDetailOrder,
      socket,
      dispatch,
    });

  const {
    mutate: mutateProgressStatusProduksi,
    isPending: isLoadingProgressProduksi,
  } = Api.progressAntrianProduksi({
    handleClose: handleCloseDetailOrder,
    socket,
    dispatch,
  });

  const { mutate: mutateKirimAntrian, isPending: isLoadingKirimAntrian } =
    Api.checkoutKirim({
      handleClose: handleCloseDetailOrder,
      socket,
      dispatch,
    });

  const onSubmitDesain = (values) => {
    mutateAmbilAntrian({ id: data.data.id, body: values });
  };

  const onSubmitProduksi = (values) => {
    mutateAmbilAntrian({ id: data.data.id, body: values });
  };

  const onSubmitUpdateStatusProduksi = (values) => {
    mutateProgressStatusProduksi({ id: data.data.id, body: values });
  };

  const onSubmitKirim = (values) => {
    mutateKirimAntrian({ id: data.data.id, body: values });
  };

  return (
    <>
      <BlankDialog
        maxWidth={"xs"}
        open={scanOrder.open}
        handleClose={handleClose}
        title={scanOrder.title}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          paddingY={2}
        >
          <img
            src={Barcode}
            alt="Barcode"
            style={{ width: 120, marginBottom: "1rem" }}
          />
          <Typography fontWeight={700} color={"#797979"}>
            {scanOrder.ket}
          </Typography>
          <input
            ref={inputRef}
            autoComplete="off"
            className="hide-input"
            onChange={handleChange}
            onBlur={() => setFocused(false)}
            onFocus={(event) => {
              event.target.select();
              setFocused(true);
            }}
            disabled={isLoading ? true : false}
          />
        </Stack>
      </BlankDialog>
      <DetailOrderScanLayout
        form={form}
        handleClose={handleCloseDetailOrder}
        data={data ? data.data : {}}
        isLoadingData={isLoading}
        isLoadingSubmit={
          isLoadingDesain || isLoadingProgressProduksi || isLoadingKirimAntrian
        }
        onSubmitDesain={form.handleSubmit(onSubmitDesain)}
        onSubmitProduksi={form.handleSubmit(onSubmitProduksi)}
        onSubmitUpdateStatusProduksi={form.handleSubmit(
          onSubmitUpdateStatusProduksi
        )}
        onSubmitKirim={form.handleSubmit(onSubmitKirim)}
      />
    </>
  );
};

export default ScanBarcodeOrder;
