import { configureStore } from "@reduxjs/toolkit";
import themeReducer from "../features/themeSlice";
import authReducer from "../features/authSlice";
import notificationReducer from "../features/notificationSlice";
import dataReducer from "../features/app/domain/dataSlice";
import manageGroupPageReducer from "../features/manageGroup/page/domain/manageGroupPageSlice";
import manageGroupReducer from "../features/manageGroup/group/domain/manageGroupSlice";
import manageGroupNotaReducer from "../features/manageGroup/nota/domain/manageGroupNotaSlice";
import userReducer from "../features/users/domain/userSlice";
import dikerjakanReducer from "../features/dikerjakan/page/domain/dikerjakanSlice";
import dikerjakanNotaReducer from "../features/dikerjakan/nota/domain/dikerjakanNotaSlice";
import dikerjakanGroupReducer from "../features/dikerjakan/group/domain/dikerjakanGroupSlice";
import returReducer from "../features/retur/domain/returSlice";

// ANTRIAN FEATURE
import antrianPageReducer from "../features/antrian/page/domain/antrianSlice";
import antrianNotaReducer from "../features/antrian/nota/domain/antrianNotaSlice";
import antrianGroupReducer from "../features/antrian/group/domain/antrianGroupSlice";

import checkoutReducer from "../features/checkout/page/domain/checkoutSlice";
import checkoutSiapKirimReducer from "../features/checkout/siapkirim/domain/siapKirimSlice";
import checkoutDikirimReducer from "../features/checkout/dikirim/domain/DikirimSlice";
import pendapatanReducer from "../features/pendapatan/page/domain/pendapatanSlice";
import pendapatanBulananReducer from "../features/pendapatan/bulanan/domain/pendapatanBulananSlice";
import pendapatanTahunanReducer from "../features/pendapatan/tahunan/domain/pendapatanTahunanSlice";
import cashflowReducer from "../features/cashflow/page/domain/cashflowSlice";
import cashflowBulananReducer from "../features/cashflow/bulanan/domain/cashflowBulananSlice";
import riwayarBayarReducer from "../features/cashflow/riwayatBayar/domain/riwayarBayarSlice";
import cashflowTahunanReducer from "../features/cashflow/tahunan/domain/cashflowTahunanSlice";
import rekapReducer from "../features/rekap/page/domain/rekapSlice";
import rekapNotaReducer from "../features/rekap/nota/domain/rekapNotaSlice";
import rekapRejectReducer from "../features/rekap/reject/domain/rekapRejectSlice";
import pengaturanReducer from "../features/pengaturan/page/domain/pengaturanSlice";
import pengaturanProdukReducer from "../features/pengaturan/produk/domain/penganturanProdukSlice";
import pengaturanTargetReducer from "../features/pengaturan/target/domain/pengaturanTargetSlice";
import groupReducer from "../features/groupSlice";
import dashboardGroupPencapaianReducer from "../features/dashboard/CardGroupPencapaian/domain/dashboardGroupPencapaianSlice";
import dashboardCardJenisOrderReducer from "../features/dashboard/CardGrafikJenisOrder/domain/dashboardCardJenisOrderSlice";
import dashboardCardSourceOrderReducer from "../features/dashboard/CardGrafikSourceOrder/domain/dashboardCardSourceOrderSlice";
import tabelGrafikPembayaranReducer from "../features/dashboard/TabelGrafikPembayaran/domain/tabelGrafikPembayaranSlice";
import tabelGrafikRejectReducer from "../features/dashboard/TabelGrafikReject/domain/tabelGrafikRejectSlice";
import pencapaianReducer from "../features/pencapaian/page/domain/pencapaianSlice";
import pencapaianNotaReducer from "../features/pencapaian/nota/domain/pencapaianNotaSlice";
import pencapaianGroupReducer from "../features/pencapaian/group/domain/pencapaianGroupSlice";
import scannerReducer from "../features/scanner/domain/scannerSlice";
import imageDetailReducer from "../features/imageDetailSlice";

export const store = configureStore({
  reducer: {
    notification: notificationReducer,
    theme: themeReducer,
    auth: authReducer,
    data: dataReducer,
    manageGroupPage: manageGroupPageReducer,
    manageGroup: manageGroupReducer,
    manageGroupNota: manageGroupNotaReducer,
    user: userReducer,
    dikerjakan: dikerjakanReducer,
    dikerjakanNota: dikerjakanNotaReducer,
    dikerjakanGroup: dikerjakanGroupReducer,
    retur: returReducer,

    antrian: antrianPageReducer,
    antrianNota: antrianNotaReducer,
    antrianGroup: antrianGroupReducer,

    checkout: checkoutReducer,
    checkoutSiapKirim: checkoutSiapKirimReducer,
    checkoutDikirim: checkoutDikirimReducer,
    pendapatan: pendapatanReducer,
    pendapatanBulanan: pendapatanBulananReducer,
    pendapatanTahunan: pendapatanTahunanReducer,
    cashflow: cashflowReducer,
    cashflowBulanan: cashflowBulananReducer,
    cashflowTahunan: cashflowTahunanReducer,
    riwayatBayar: riwayarBayarReducer,
    rekap: rekapReducer,
    rekapNota: rekapNotaReducer,
    rekapReject: rekapRejectReducer,
    pengaturan: pengaturanReducer,
    pengaturanProduk: pengaturanProdukReducer,
    pengaturanTarget: pengaturanTargetReducer,
    group: groupReducer,
    dashboardGroupPencapaian: dashboardGroupPencapaianReducer,
    dashboardCardJenisOrder: dashboardCardJenisOrderReducer,
    dashboardCardSourceOrder: dashboardCardSourceOrderReducer,
    tabelGrafikPembayaran: tabelGrafikPembayaranReducer,
    tabelGrafikReject: tabelGrafikRejectReducer,
    pencapaian: pencapaianReducer,
    pencapaianNota: pencapaianNotaReducer,
    pencapaianGroup: pencapaianGroupReducer,
    scanner: scannerReducer,
    imageDetail: imageDetailReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
