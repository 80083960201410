import dayjs from "dayjs";
import { Chip, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import logoWave from "../../../../assets/icon/logo/waveprint.svg";
import config from "../../../../config";
import QRCode from "react-qr-code";
import md5 from "md5";
import Barcode from "react-barcode";
import { NumericText } from "../../../common";
import { dateFormat } from "../../../controls";

const DatePrint = dayjs();
const formattedDate = DatePrint.format("DD-MM-YYYY HH:mm");

const PrintAntrianLayout = React.forwardRef((props, ref) => {
  const { data } = props;
  const { setting } = useSelector((state) => state.data);
  const [pengaturan, setPengaturan] = useState({
    nama: "",
    alamat: "",
    no_hp: "",
    logo: "",
  });

  const [dataDetail, setDataDetail] = useState({
    nota: "-",
    nama: "-",
    jenisOrder: "-",
    checkIn: "-",
    adminCheckIn: "-",
    checkOut: "-",
    adminCheckOut: "-",
    size: [],
    ekspedisi: "-",
    ekspedisi_keterangan: null,
    pembayaran: 0,
    pembelian: {
      total: 0,
      qty: 0,
    },
  });

  useEffect(() => {
    if (data) {
      if (data.detail) {
        setDataDetail({
          nota: data.antrian.nota,
          nama: data.detail.nama,
          jenisOrder: data.antrian.jenis_order.nama,
          checkIn: dateFormat(data.progress.check_in.tanggal, "full", "-"),
          adminCheckIn: `${data.progress.check_in.usercode} ${data.progress.check_in.nama}`,
          checkOut: data.progress.check_out.tanggal
            ? dateFormat(data.progress.check_out.tanggal, "full", "-")
            : null,
          adminCheckOut: data.progress.check_out.usercode
            ? `${data.progress.check_in.usercode} ${data.progress.check_in.nama}`
            : null,
          size: data.total.size,
          ekspedisi: data.antrian.ekspedisi.nama,
          ekspedisi_keterangan: data.antrian.ekspedisi.keterangan,
          pembayaran: data.total.pembayaran.total,
          pembelian: {
            total: data.total.pembelian.total,
            qty: data.total.pembelian.qty,
          },
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (setting.length) {
      const nama = setting.find((e) => e.rule === "NAMA_TOKO");
      const alamat = setting.find((e) => e.rule === "ALAMAT");
      const no_hp = setting.find((e) => e.rule === "NO_HP");
      const logo = setting.find((e) => e.rule === "LOGO_NOTA");
      if (nama) {
        setPengaturan((old) => ({ ...old, nama: nama.value }));
      }
      if (alamat) {
        setPengaturan((old) => ({ ...old, alamat: alamat.value }));
      }
      if (no_hp) {
        setPengaturan((old) => ({ ...old, no_hp: no_hp.value }));
      }
      if (logo) {
        setPengaturan((old) => ({ ...old, logo: logo.value }));
      }
    }
  }, [setting]);

  return (
    <div
      ref={ref}
      style={{
        padding: "1rem",
        color: "black",
        fontSize: 12,
        fontFamily: "PrintFont",
      }}
    >
      <center style={{ paddingBottom: 5 }}>
        {pengaturan.logo && (
          <img
            src={config.apiUrl + "/storage/logo/" + pengaturan.logo}
            alt="Logo"
            width={"30px"}
            height={"30px"}
          />
        )}
        <Stack
          spacing={0}
          sx={{ letterSpacing: "0.2px", lineHeight: "12.5px", marginTop: 0.5 }}
        >
          <div style={{ fontSize: 13, fontWeight: 700 }}>{pengaturan.nama}</div>
          <div style={{ fontSize: 10 }}>{pengaturan.alamat}</div>
          <div style={{ fontSize: 10 }}>{pengaturan.no_hp}</div>
        </Stack>
      </center>
      <Divider sx={{ borderBottomWidth: 1, bgcolor: "black" }} />
      <table
        width={"100%"}
        style={{
          paddingTop: 10,
          paddingBottom: 5,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <tbody style={{ lineHeight: "1.3", width: "80%" }}>
          <tr>
            <td
              width={"45%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              <b>No. Nota</b>
            </td>
            <td
              width={"5%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              :
            </td>
            <td align={"left"} style={{ alignContent: "baseline" }}>
              <b>{dataDetail.nota}</b>
            </td>
          </tr>
          <tr>
            <td
              width={"45%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              Leads / Nama
            </td>
            <td
              width={"5%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              :
            </td>
            <td
              width={"50%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              {dataDetail.nama.length > 12
                ? `${dataDetail.nama.slice(0, 12)}...`
                : dataDetail.nama}
            </td>
          </tr>
          <tr>
            <td
              width={"45%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              Jenis
            </td>
            <td
              width={"5%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              :
            </td>
            <td align={"left"} style={{ alignContent: "baseline" }}>
              {dataDetail.jenisOrder}
            </td>
          </tr>
          <tr>
            <td
              width={"45%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              Check In
            </td>
            <td
              width={"5%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              :
            </td>
            <td align={"left"} style={{ alignContent: "baseline" }}>
              {dataDetail.checkIn}
            </td>
          </tr>
          <tr>
            <td
              width={"45%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              Admin Check In
            </td>
            <td
              width={"5%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              :
            </td>
            <td align={"left"} style={{ alignContent: "baseline" }}>
              {dataDetail.adminCheckIn}
            </td>
          </tr>
          {dataDetail.checkOut && dataDetail.adminCheckOut && (
            <>
              <tr>
                <td
                  width={"45%"}
                  align={"left"}
                  style={{ alignContent: "baseline" }}
                >
                  Check Out
                </td>
                <td
                  width={"5%"}
                  align={"left"}
                  style={{ alignContent: "baseline" }}
                >
                  :
                </td>
                <td align={"left"} style={{ alignContent: "baseline" }}>
                  {dataDetail.checkOut}
                </td>
              </tr>
              <tr>
                <td
                  width={"45%"}
                  align={"left"}
                  style={{ alignContent: "baseline" }}
                >
                  Admin Check Out
                </td>
                <td
                  width={"5%"}
                  align={"left"}
                  style={{ alignContent: "baseline" }}
                >
                  :
                </td>
                <td align={"left"} style={{ alignContent: "baseline" }}>
                  {dataDetail.adminCheckOut}
                </td>
              </tr>
            </>
          )}
        </tbody>
        <tbody>
          <tr>
            <td rowSpan={4}>
              <QRCode value={md5(dataDetail.nota)} size={100} />
            </td>
          </tr>
        </tbody>
      </table>
      <Divider
        sx={{
          "&::before, &::after": {
            borderBottomWidth: 1,
            borderTopWidth: 1,
            borderColor: "black",
          },
        }}
      >
        <Chip
          label="Detail Order"
          sx={{
            backgroundColor: "#000",
            color: "#fff",
            height: "100%",
            fontSize: "8px",
          }}
        />
      </Divider>
      <table
        width={"100%"}
        style={{ marginTop: 5, marginBottom: 5, lineHeight: "1.5" }}
      >
        <thead>
          <tr style={{ fontWeight: 600 }}>
            <td align="left" width={"40%"}>
              Size
            </td>
            <td align="center" width={"10%"}>
              Qty
            </td>
            <td align="center" width={"10%"}>
              Add
            </td>
            <td align="right" width={"20%"}>
              Harga
            </td>
            <td align="right" width={"20%"}>
              Sub Total
            </td>
          </tr>
        </thead>
        <tbody>
          {dataDetail.size.map((size, indexSize) => (
            <React.Fragment key={indexSize}>
              <tr style={{ fontSize: 11, fontWeight: 600 }}>
                <td align="left">{size.nama}</td>
                <td align="center">
                  <NumericText value={size.qty} />
                </td>
                <td align="center"></td>
                <td align="right">
                  <NumericText value={size.harga} />
                </td>
                <td align="right">
                  <NumericText value={size.harga * size.qty} />
                </td>
              </tr>
              {size.additional.map((e, indexItems) => (
                <tr key={indexItems} style={{ fontSize: "10px" }}>
                  <td align="left">
                    <li style={{ marginLeft: "5px" }}>{e.nama}</li>
                  </td>
                  <td align="center"></td>
                  <td align="center">
                    <NumericText value={e.qty} />
                  </td>
                  <td align="right">
                    <NumericText value={e.harga} />
                  </td>
                  <td align="right">
                    <NumericText value={e.harga * e.qty} />
                  </td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <Divider sx={{ borderBottomWidth: 1, bgcolor: "black", marginY: 0.5 }} />
      <table width={"100%"}>
        <tbody>
          <tr style={{ fontWeight: 600 }}>
            <td align="left" width={"40%"}>
              Total
            </td>
            <td align="left" width={"30%"}>
              {dataDetail.pembelian.qty + " Pcs"}
            </td>
            <td align="right">
              <NumericText value={dataDetail.pembelian.total} prefix />
            </td>
          </tr>
        </tbody>
      </table>
      <Divider sx={{ borderBottomWidth: 1, bgcolor: "black", marginY: 0.5 }} />
      <table width={"100%"} style={{ fontSize: 10 }}>
        <thead>
          <tr>
            <td align="left" width={"30%"}>
              Total Terbayar
            </td>
            <td align="left" width={"30%"}></td>
            <td align="right">
              <NumericText value={dataDetail.pembayaran} prefix />
            </td>
          </tr>

          <tr style={{}}>
            <td align="left" width={"30%"}>
              {dataDetail.pembayaran >= dataDetail.pembelian.total
                ? "Kembalian"
                : "Kekurangan"}
            </td>
            <td align="left" width={"30%"}></td>
            <td align="right">
              <NumericText
                value={Math.abs(
                  dataDetail.pembayaran - dataDetail.pembelian.total
                )}
                prefix
              />
            </td>
          </tr>
        </thead>
      </table>

      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0.5rem 0 0 0",
          }}
        >
          <Typography sx={{ fontSize: 9, fontWeight: 700 }}>
            Ekspedisi
          </Typography>
          <Typography sx={{ fontSize: 9, fontWeight: 700 }}>
            {dataDetail.ekspedisi}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "0.5rem 0 0 0",
          }}
        >
          {dataDetail.ekspedisi_keterangan && (
            <Barcode
              height={40}
              width={2}
              value={dataDetail.ekspedisi_keterangan}
              background="transparent"
              fontSize={10}
              marginTop={-0.5}
              marginBottom={-0.4}
            />
          )}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem 0 0 0",
        }}
      >
        <Typography sx={{ fontSize: 8 }}>
          Printed on : {formattedDate}
        </Typography>
        <Typography sx={{ fontSize: 7 }}>
          Powered by{" "}
          <img src={logoWave} alt="logoWave" style={{ width: "30px" }} />
        </Typography>
      </div>
    </div>
  );
});

export default PrintAntrianLayout;
