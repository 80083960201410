import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SET_USER } from "../features/authSlice";
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Button,
  CssBaseline,
  Typography,
  Divider,
} from "@mui/material";
import { Input } from "../components/controls";
import { useForm } from "react-hook-form";
import useAuth from "../hooks/useAuth";
import LogoWave from "../assets/icon/logo/wave-light.svg";
import LoginIcon from "@mui/icons-material/Login";

function Copyright(props) {
  const { namaToko } = props;
  return (
    <>
      <Typography variant="body2" color="#d3d3d3" align="center" sx={{ mt: 8 }}>
        Registered to : {namaToko === "" ? "Wave" : namaToko}
      </Typography>
      <Typography
        sx={{
          fontWeight: 400,
          color: "white",
          textDecoration: "none",
          fontSize: 10,
          textAlign: "center",
        }}
      >
        Copyright TenTackle © 2024 - All Right Reserved
      </Typography>
    </>
  );
}

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { username } = useSelector((state) => state.auth);
  const { setting } = useSelector((state) => state.data);
  const [namaToko, setNamaToko] = React.useState("");
  const { control, handleSubmit } = useForm();

  // CHANGE TITLE
  React.useEffect(() => {
    if (setting.length) {
      const judul = setting.filter((e) => e.rule === "NAMA_TOKO");
      if (judul.length) {
        document.title = `${judul[0].value} | Login`;
        setNamaToko(judul[0].value);
      } else {
        document.title = `Login`;
      }
    } else {
      document.title = "Login";
    }
  }, [setting]);

  const { mutate: loginMutate, isPending: isLoading } = useAuth({
    onSuccess: (res) => {
      localStorage.setItem("token", res.data.data.access_token);
      dispatch({
        type: SET_USER,
        payload: {
          ...res.data.data,
        },
      });
    },
  });

  const onSubmit = (props) => {
    loginMutate({
      username: props.username,
      password: props.password,
    });
  };

  React.useEffect(() => {
    if (username) {
      navigate("/", { replace: true });
    }
  }, [username, navigate]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100vh", backgroundColor: "#566A7D" }}
    >
      <Paper elevation={4} sx={{ p: 2, borderRadius: 2.5 }}>
        <CssBaseline />
        <Stack paddingBottom={3} marginTop={1} alignItems={"center"}>
          <img src={LogoWave} alt="LogoWave" width={"50%"} />
        </Stack>

        <Stack
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            width: { xl: "50ch", md: "40ch" },
          }}
          spacing={{ xl: 2.5, md: 2 }}
          noValidate
          autoComplete="off"
        >
          <Input
            name={"username"}
            control={control}
            label={"Username"}
            rules={{ required: "Username Wajib di isi!" }}
          />
          <Input
            name={"password"}
            control={control}
            label={"Password"}
            rules={{ required: "Password Wajib di isi!" }}
            type={"password"}
          />
          <Divider
            sx={{
              marginX: "-1rem !important",
              marginTop: "1.5rem !important",
              borderColor: "#ABABAB",
            }}
          />
          <Box
            sx={{
              position: "relative",
              textAlign: "center",
            }}
          >
            <Button
              startIcon={!isLoading ? <LoginIcon /> : null}
              variant="contained"
              disabled={isLoading ? true : false}
              // disabled
              type="submit"
              sx={{
                width: "-webkit-fill-available",
                backgroundColor: "#35AAFF",
                "&:hover": {
                  backgroundColor: "#1ea0fd",
                },
                "&:disabled": {
                  backgroundColor: "#1ea0fd",
                  color: "#fff",
                },
              }}
            >
              {!isLoading
                ? "Login"
                : isLoading && <CircularProgress color="inherit" size={22} />}
            </Button>
          </Box>
        </Stack>
      </Paper>
      <Stack marginTop={1}>
        <Typography variant="body2" color="#d3d3d3" align="center">
          {/* {`Version ${app_version} ${description}`} */}
          {`Version 2.0.5 (Beta)`}
        </Typography>
      </Stack>
      <Stack position={"absolute"} bottom={30}>
        <Copyright namaToko={namaToko} />
      </Stack>
    </Grid>
  );
};

export default Login;
