import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Divider, Skeleton } from "@mui/material";
import NotaProgress from "./Progress/NotaProgress";
import LoadingProgress from "../../../common/LoadingProgress";
import BlankDialog from "../BlankDialog";
import { dateFormatter } from "../../../controls";
import { ChipItems } from "../../../common";

const DetailProgressLayout = (props) => {
  const { open, handleClose, title, data, isLoading } = props;

  const [lastStatus, setLastStatus] = useState({
    progress: 0,
    warna: "#FFF",
  });

  useEffect(() => {
    setLastStatus({
      progress: 0,
      warna: "#FF",
    });
    if (data) {
      if (data.progress) {
        if (data.progress.length > 0) {
          const filteredLast = data.progress[data.progress.length - 1];
          setLastStatus({
            progress: filteredLast.status.progress,
            warna: filteredLast.status.warna,
          });
        }
      }
    }
  }, [data]);

  return (
    <BlankDialog
      open={open}
      handleClose={handleClose}
      title={title}
      isLoading={isLoading}
    >
      <Grid
        container
        sx={{
          justifyContent: "space-between",
          fontSize: "12px",
          color: "#797979",
          fontWeight: "400",
        }}
      >
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          {isLoading ? (
            <Skeleton width={"100%"} />
          ) : (
            <LoadingProgress
              progress={lastStatus.progress}
              barWidth={"100%"}
              barcolor={lastStatus.warna}
            />
          )}
        </Grid>
        <Grid
          container
          item
          marginTop={2}
          borderRadius="5px"
          sx={{ backgroundColor: "#F3F7F8", padding: "1rem" }}
        >
          <NotaProgress data={data} isLoading={isLoading} />
        </Grid>
      </Grid>
      <Box
        marginTop={2}
        sx={{ maxWidth: "100%" }}
        display={"flex"}
        flexDirection={"column-reverse"}
      >
        {!isLoading ? (
          data && data.progress ? (
            data.progress.map((item, index) => (
              <Grid container padding={1.5} key={index}>
                <Grid item xs={3} textAlign={"end"} fontSize={"10px"}>
                  <Grid item>
                    {dateFormatter({
                      date: new Date(item.tanggal),
                      withHari: true,
                      withMonth: "simple",
                      withTimes: false,
                      yearLength: "full",
                    })}
                  </Grid>
                  <Grid item>
                    {dateFormatter({
                      date: new Date(item.tanggal),
                      timesOnly: true,
                    })}
                  </Grid>
                </Grid>
                <Grid item xs={1} display={"flex"} justifyContent={"center"}>
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "-0.9rem",
                    }}
                  >
                    <Divider
                      orientation="vertical"
                      sx={{
                        position: "absolute",
                        borderLeft:
                          index !== 0
                            ? "thin dashed rgba(0, 0, 0, 0.12)"
                            : "none",
                        borderRight: "none",
                        zIndex: 5,
                        height: "100%",
                        marginTop: "0.75rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        "&.MuiDivider-root": {
                          "&::before": {
                            border: "none",
                          },
                          "&::after": {
                            border: "none",
                          },
                        },
                      }}
                    />
                    <div
                      style={{
                        marginTop: "0.25rem",
                        zIndex: 6,
                        width: "7px",
                        height: "7px",
                        background:
                          index === data.progress.length - 1
                            ? item.status.warna
                            : "#D9D9D9",
                        borderRadius: "100%",
                        opacity: 1,
                      }}
                    >
                      <div
                        style={{
                          zIndex: 7,
                          width: "7px",
                          height: "7px",
                          outlineWidth: `2px`,
                          outlineStyle: "solid",
                          outlineColor:
                            index === data.progress.length - 1
                              ? item.status.warna
                              : "rgba(217, 217, 217, 0.01)",
                          borderRadius: "100%",
                          opacity: 0.5,
                        }}
                      />
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={8} textAlign={"start"}>
                  {index === 0 && (
                    <Grid item paddingBottom={0.5}>
                      <Typography fontSize={10} fontWeight={700} color={"#000"}>
                        Check in order !
                      </Typography>
                    </Grid>
                  )}
                  <Grid
                    item
                    marginBottom={0.5}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Typography
                      fontSize={10}
                      fontWeight={700}
                      color={"#A3A3A3"}
                    >
                      {index !== 0 ? "Updated to" : "Inserted to"}
                    </Typography>
                    <ChipItems
                      label={item.status.nama}
                      backgroundcolor={item.status.warna}
                      borderradius={"3px"}
                      sx={{
                        fontSize: "10px",
                        margin: "0 5px 0 5px",
                        height: "100%",
                      }}
                    />
                    <Typography
                      fontSize={10}
                      fontWeight={700}
                      color={"#A3A3A3"}
                    >
                      by {item.user.kode_user} : {item.user.nama}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    paddingRight={1}
                    marginBottom={0.5}
                    fontSize={10}
                    color={"#A3A3A3"}
                  >
                    {item.keterangan}
                  </Grid>
                </Grid>
              </Grid>
            ))
          ) : (
            <div style={{ flex: 1, textAlign: "center" }}>
              Tidak ada progress!
            </div>
          )
        ) : (
          <>
            <Grid container padding={1.5}>
              <Grid item xs={3} textAlign={"end"} fontSize={"10px"}>
                <Grid item>
                  <Skeleton />
                </Grid>
                <Grid item>
                  <Skeleton />
                </Grid>
              </Grid>
              <Grid item xs={1} display={"flex"} justifyContent={"center"}>
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "-0.9rem",
                  }}
                >
                  <Divider
                    orientation="vertical"
                    sx={{
                      position: "absolute",
                      // borderLeft: "thin dashed rgba(0, 0, 0, 0.12)",
                      borderLeft: "none",
                      borderRight: "none",
                      zIndex: 5,
                      height: "100%",
                      marginTop: "0.75rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      "&.MuiDivider-root": {
                        "&::before": {
                          border: "none",
                        },
                        "&::after": {
                          border: "none",
                        },
                      },
                    }}
                  />
                  <div
                    style={{
                      marginTop: "0.25rem",
                      zIndex: 6,
                      width: "7px",
                      height: "7px",
                      outlineWidth: `2px`,
                      outlineStyle: "solid",
                      outlineColor: "grey",
                      background: "grey",
                      borderRadius: "100%",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={8} textAlign={"start"}>
                <Grid item paddingBottom={0.5}>
                  <Skeleton width={"30%"} />
                </Grid>
                <Grid
                  item
                  marginBottom={0.5}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Skeleton width={"25%"} />
                </Grid>
                <Grid
                  item
                  paddingRight={1}
                  marginBottom={0.5}
                  fontSize={10}
                  color={"#A3A3A3"}
                >
                  <Skeleton width={"65%"} />
                </Grid>
              </Grid>
            </Grid>

            <Grid container padding={1.5}>
              <Grid item xs={3} textAlign={"end"} fontSize={"10px"}>
                <Grid item>
                  <Skeleton />
                </Grid>
                <Grid item>
                  <Skeleton />
                </Grid>
              </Grid>
              <Grid item xs={1} display={"flex"} justifyContent={"center"}>
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "-0.9rem",
                  }}
                >
                  <Divider
                    orientation="vertical"
                    sx={{
                      position: "absolute",
                      borderLeft: "thin dashed rgba(0, 0, 0, 0.12)",
                      borderRight: "none",
                      zIndex: 5,
                      height: "100%",
                      marginTop: "0.75rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      "&.MuiDivider-root": {
                        "&::before": {
                          border: "none",
                        },
                        "&::after": {
                          border: "none",
                        },
                      },
                    }}
                  />
                  <div
                    style={{
                      marginTop: "0.25rem",
                      zIndex: 6,
                      width: "7px",
                      height: "7px",
                      outlineWidth: `2px`,
                      outlineStyle: "solid",
                      outlineColor: "grey",
                      background: "grey",
                      borderRadius: "100%",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={8} textAlign={"start"}>
                <Grid item paddingBottom={0.5}>
                  <Skeleton width={"30%"} />
                </Grid>
                <Grid
                  item
                  marginBottom={0.5}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Skeleton width={"25%"} />
                </Grid>
                <Grid
                  item
                  paddingRight={1}
                  marginBottom={0.5}
                  fontSize={10}
                  color={"#A3A3A3"}
                >
                  <Skeleton width={"65%"} />
                </Grid>
              </Grid>
            </Grid>

            <Grid container padding={1.5}>
              <Grid item xs={3} textAlign={"end"} fontSize={"10px"}>
                <Grid item>
                  <Skeleton />
                </Grid>
                <Grid item>
                  <Skeleton />
                </Grid>
              </Grid>
              <Grid item xs={1} display={"flex"} justifyContent={"center"}>
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "-0.9rem",
                  }}
                >
                  <Divider
                    orientation="vertical"
                    sx={{
                      position: "absolute",
                      borderLeft: "thin dashed rgba(0, 0, 0, 0.12)",
                      borderRight: "none",
                      zIndex: 5,
                      height: "100%",
                      marginTop: "0.75rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      "&.MuiDivider-root": {
                        "&::before": {
                          border: "none",
                        },
                        "&::after": {
                          border: "none",
                        },
                      },
                    }}
                  />
                  <div
                    style={{
                      marginTop: "0.25rem",
                      zIndex: 6,
                      width: "7px",
                      height: "7px",
                      outlineWidth: `2px`,
                      outlineStyle: "solid",
                      outlineColor: "grey",
                      background: "grey",
                      borderRadius: "100%",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={8} textAlign={"start"}>
                <Grid item paddingBottom={0.5}>
                  <Skeleton width={"30%"} />
                </Grid>
                <Grid
                  item
                  marginBottom={0.5}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Skeleton width={"25%"} />
                </Grid>
                <Grid
                  item
                  paddingRight={1}
                  marginBottom={0.5}
                  fontSize={10}
                  color={"#A3A3A3"}
                >
                  <Skeleton width={"65%"} />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </BlankDialog>
  );
};

export default DetailProgressLayout;
