import React, { useRef, useState, useEffect } from "react";
import {
  AppBar,
  Stack,
  Tab,
  Tabs,
  Button,
  Grid,
  Skeleton,
} from "@mui/material";
import Layout from "../layout/Layout";
import { Select } from "../components/controls";
import {
  EventHandlers,
  bulanItems,
  tahunItems,
} from "../features/cashflow/page/repository";
import {
  EventHandlers as EventHandlersBulanan,
  useFormCashflowBulanan,
} from "../features/cashflow/bulanan/repository";
import {
  EventHandlers as EventHandlersTahunan,
  useFormCashflowTahunan,
} from "../features/cashflow/tahunan/repository";
import { Api as ApiRiwayatBayar } from "../features/cashflow/riwayatBayar/repository";
import { useReactToPrint } from "react-to-print";
import { Print as PrintIcon } from "@mui/icons-material";
import { TabPanel, a11yProps } from "../components/common";
import { useDispatch, useSelector } from "react-redux";
import BulananPage from "../features/cashflow/bulanan/BulananPage";
import TahunanPage from "../features/cashflow/tahunan/TahunanPage";
import RiwayatBayarPage from "../features/cashflow/riwayatBayar/RiwayatBayarPage";
import { updateLoading } from "../features/themeSlice";

const CashFlow = () => {
  const dispatch = useDispatch();
  const formBulanan = useFormCashflowBulanan();
  const formTahunan = useFormCashflowTahunan();
  const { tab } = useSelector((state) => state.cashflow);
  const { user, role } = useSelector((state) => state.auth);
  const [isLoadingPB, setIsLoadingPB] = useState(false);
  const [isLoadingPT, setIsLoadingPT] = useState(false);
  const [isLoadingRB, setIsLoadingRB] = useState(false);

  const { tanggalRiwayatBayarFilter } = useSelector(
    (state) => state.riwayatBayar
  );

  const getRiwayatBayar = ApiRiwayatBayar.read({
    startDate: tanggalRiwayatBayarFilter[0],
    endDate: tanggalRiwayatBayarFilter[1],
  });

  const handleLoadingPrint = () => {
    setIsLoadingPB(true);
    setIsLoadingPT(true);
    setIsLoadingRB(true);
    setTimeout(() => {
      setIsLoadingPB(false);
      setIsLoadingPT(false);
      setIsLoadingRB(false);
    }, 1000);
  };

  useEffect(() => {
    dispatch(updateLoading(isLoadingPB || isLoadingPT || isLoadingRB));
  }, [dispatch, isLoadingPB, isLoadingPT, isLoadingRB]);

  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  //print
  const componentRefPB = useRef();
  const componentRefPT = useRef();
  const componentRefRB = useRef();

  const handlePrintPB = useReactToPrint({
    content: () => componentRefPB.current,
  });

  const handlePrintPT = useReactToPrint({
    content: () => componentRefPT.current,
  });

  const handlePrintRB = useReactToPrint({
    content: () => componentRefRB.current,
  });

  // Loading state
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  if (loading && !role) {
    return (
      <Layout>
        <Stack spacing={1.5}>
          <Skeleton variant="rounded" width={"100%"} height={450} />
        </Stack>
      </Layout>
    );
  }

  return (
    <Layout>
      {/* Title Container */}
      {role === "root" && (
        <Stack
          sx={{ pb: 1.5 }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid
            container
            columnSpacing={1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Grid item>
              <Button
                onClick={() => {
                  handleLoadingPrint();
                  handlePrintRB();
                }}
                variant="outlined"
                startIcon={<PrintIcon />}
                sx={{
                  padding: "7px 14px",
                  borderRadius: "5px",
                }}
                disabled={getRiwayatBayar?.data?.data.length === 0}
              >
                Print
              </Button>
            </Grid>
          </Grid>
        </Stack>
      )}

      {role === "customer_service" || role === "owner" ? (
        <Stack
          sx={{ pb: 1.5 }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid
            container
            columnSpacing={1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            {tab === 0 && (
              <>
                <Grid item>
                  <Select
                    name={`bulanan.bulan`}
                    control={formBulanan.control}
                    label={"Bulan"}
                    options={bulanItems}
                    rules={{ required: false }}
                    defaultValue={currentMonth}
                    size={"small"}
                    onChange={(_, value) => {
                      formBulanan.setValue(`bulanan.bulan`, value.props.value);
                      EventHandlersBulanan.handleBulan({
                        dispatch,
                        bulan: value.props.value,
                      });
                    }}
                    noshrinklabel
                    wave
                  />
                </Grid>
                <Grid item>
                  <Select
                    name={`bulanan.tahun`}
                    control={formBulanan.control}
                    label={"Tahun"}
                    options={tahunItems}
                    rules={{ required: false }}
                    defaultValue={currentYear}
                    size={"small"}
                    onChange={(_, value) => {
                      formBulanan.setValue(`bulanan.tahun`, value.props.value);
                      EventHandlersBulanan.handleTahun({
                        dispatch,
                        tahun: value.props.value,
                      });
                    }}
                    noshrinklabel
                    wave
                  />
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => {
                      handleLoadingPrint();
                      handlePrintPB();
                    }}
                    variant="outlined"
                    startIcon={<PrintIcon />}
                    sx={{
                      padding: "7px 14px",
                      borderRadius: "5px",
                    }}
                  >
                    Print
                  </Button>
                </Grid>
              </>
            )}
            {tab === 1 && (
              <>
                <Grid item>
                  <Select
                    name={`tahunan.tahun`}
                    control={formTahunan.control}
                    label={"Tahun"}
                    options={tahunItems}
                    rules={{ required: false }}
                    defaultValue={currentYear}
                    size={"small"}
                    onChange={(_, value) => {
                      formTahunan.setValue(`tahunan.tahun`, value.props.value);
                      EventHandlersTahunan.handleTahun({
                        dispatch,
                        tahun: value.props.value,
                      });
                    }}
                    noshrinklabel
                    wave
                  />
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => {
                      handleLoadingPrint();
                      handlePrintPT();
                    }}
                    variant="outlined"
                    startIcon={<PrintIcon />}
                    sx={{
                      padding: "7px 14px",
                      borderRadius: "5px",
                    }}
                  >
                    Print
                  </Button>
                </Grid>
              </>
            )}
            {tab === 2 && (
              <Grid item>
                <Button
                  onClick={() => {
                    handleLoadingPrint();
                    handlePrintRB();
                  }}
                  variant="outlined"
                  startIcon={<PrintIcon />}
                  sx={{
                    padding: "7px 14px",
                    borderRadius: "5px",
                  }}
                  disabled={getRiwayatBayar?.data?.data.length === 0}
                >
                  Print
                </Button>
              </Grid>
            )}
          </Grid>

          <AppBar
            position="static"
            elevation={0}
            sx={{
              backgroundColor: "#fff",
              width: "400px",
            }}
          >
            <Tabs
              variant="fullWidth"
              value={tab}
              centered
              onChange={(_, value) =>
                EventHandlers.handleTab({ dispatch, tab: value })
              }
            >
              <Tab {...a11yProps(0)} label="BULANAN" />
              <Tab {...a11yProps(1)} label="TAHUNAN" />
              <Tab {...a11yProps(2)} label="RIWAYAT BAYAR" />
            </Tabs>
          </AppBar>
        </Stack>
      ) : null}
      {/* Content */}
      {role === "root" ? (
        <div>
          <RiwayatBayarPage {...{ user, componentRefRB }} />
        </div>
      ) : role === "customer_service" || role === "owner" ? (
        <>
          <TabPanel value={tab} index={0}>
            <BulananPage {...{ user, componentRefPB }} />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <TahunanPage {...{ user, componentRefPT }} />
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <RiwayatBayarPage {...{ user, componentRefRB }} />
          </TabPanel>
        </>
      ) : null}
    </Layout>
  );
};

export default CashFlow;
