import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Divider,
  Skeleton,
  Collapse,
  Chip,
} from "@mui/material";
import BlankDialog from "../BlankDialog";
import {
  CheckboxInput,
  CustomButton,
  dateFormatter,
  Input,
  Select,
} from "../../../controls";
import { ActionGrid, ChipItems, CollapseButton } from "../../../common";
import DataForm from "../DataForm";
import { useSelector } from "react-redux";
import { useGetCachedData } from "../../../../hooks";

const DetailOrderScanLayout = ({
  form,
  isLoadingData = false,
  isLoadingSubmit = false,
  handleClose,
  data,
  onSubmitDesain,
  onSubmitProduksi,
  onSubmitUpdateStatusProduksi,
  onSubmitKirim,
}) => {
  const { user_id, role } = useSelector((state) => state.auth);
  const { detailOrder } = useSelector((state) => state.scanner);
  const [openProgress, setOpenProgress] = useState(false);
  const [listStatus, setListStatus] = useState([]);

  const getDataStatus = useGetCachedData({ queryKey: "status" });

  const statusBaruMasuk = getDataStatus
    ? getDataStatus.data.default.find((status) => status.kode === "S01")
    : {};

  const statusDesain = getDataStatus
    ? getDataStatus.data.default.find((status) => status.kode === "S02")
    : {};

  const statusSiapProduksi = getDataStatus
    ? getDataStatus.data.default.find((status) => status.kode === "S03")
    : {};

  const statusSiapKirim = getDataStatus
    ? getDataStatus.data.default.find((status) => status.kode === "S05")
    : {};

  const statusPengiriman = getDataStatus
    ? getDataStatus.data.default.find((status) => status.kode === "S06")
    : {};

  const statusSelesai = getDataStatus
    ? getDataStatus.data.default.find((status) => status.kode === "S07")
    : {};

  const statusRetur = getDataStatus
    ? getDataStatus.data.default.find((status) => status.kode === "S08")
    : {};

  const handleProgress = () => {
    setOpenProgress(!openProgress);
  };

  const ProgressComponent = ({ titleButton = "Simpan", onSubmit }) => {
    return (
      <form onSubmit={onSubmit}>
        <Grid container spacing={2} marginTop={0}>
          <Grid item xs={12}>
            <Input
              name="keterangan"
              control={form.control}
              label="Keterangan"
              rules={{ required: false }}
              defaultValue=""
              rows={5}
            />
          </Grid>
        </Grid>

        {/* Validation Check */}
        <Grid
          container
          justifyContent={"end"}
          alignItems={"center"}
          marginTop={1}
          marginBottom={"-1rem"}
        >
          <CheckboxInput
            name={"confirmation"}
            control={form.control}
            label={"Pastikan data sudah benar!"}
            rules={{ required: false }}
            defaultValue={false}
          />
        </Grid>

        {/* Action Button */}
        <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
        <ActionGrid
          isLoading={isLoadingSubmit || isLoadingData}
          actionStart={
            <CustomButton
              loading={isLoadingSubmit || isLoadingData}
              onClick={handleClose}
              warna={"#A4A0A0"}
              widthbtn={"100%"}
            >
              Kembali
            </CustomButton>
          }
          actionEnd={
            <CustomButton
              loading={isLoadingSubmit || isLoadingData}
              warna={"next"}
              widthbtn={"100%"}
              disabled={form.watch("confirmation") ? false : true}
              type="submit"
            >
              {titleButton}
            </CustomButton>
          }
        />
      </form>
    );
  };

  const ProduksiComponent = ({ onSubmit }) => {
    return (
      <form onSubmit={onSubmit}>
        <Grid container spacing={2} marginTop={0}>
          <Grid item xs={6}>
            <Chip
              label={data?.progress?.status?.nama ?? "-"}
              sx={{
                backgroundColor: data?.progress?.status?.warna ?? "#000",
                color: "white",
                borderRadius: "5px",
                fontWeight: 500,
                width: "100%",
                height: "100%",
              }}
              variant="filled"
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              disabled={listStatus.length ? false : true}
              name={"status_id"}
              control={form.control}
              label={"Status"}
              options={
                data?.progress?.status?.kode
                  ? listStatus.filter(
                      (status) => status.kode !== data?.progress?.status?.kode
                    )
                  : listStatus
              }
              rules={{ required: "Status Harus Dipilih!" }}
              defaultValue={""}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="keterangan"
              control={form.control}
              label="Keterangan"
              rules={{ required: false }}
              defaultValue=""
              rows={5}
            />
          </Grid>
        </Grid>

        {/* Validation Check */}
        <Grid
          container
          justifyContent={"end"}
          alignItems={"center"}
          marginTop={1}
          marginBottom={"-1rem"}
        >
          <CheckboxInput
            name={"confirmation"}
            control={form.control}
            label={"Pastikan data sudah benar!"}
            rules={{ required: false }}
            defaultValue={false}
          />
        </Grid>

        {/* Action Button */}
        <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
        <ActionGrid
          isLoading={isLoadingSubmit || isLoadingData}
          actionStart={
            <CustomButton
              loading={isLoadingSubmit || isLoadingData}
              onClick={handleClose}
              warna={"#A4A0A0"}
              widthbtn={"100%"}
            >
              Kembali
            </CustomButton>
          }
          actionEnd={
            <CustomButton
              loading={isLoadingSubmit || isLoadingData}
              warna={"next"}
              widthbtn={"100%"}
              disabled={form.watch("confirmation") ? false : true}
              type="submit"
            >
              Update
            </CustomButton>
          }
        />
      </form>
    );
  };

  const CommonComponent = () => {
    return (
      <>
        <Divider sx={{ marginY: 2, marginX: "-1rem" }} />

        <Grid container spacing={2} justifyContent={"end"}>
          <Grid item xs={12}>
            <CustomButton
              loading={isLoadingSubmit || isLoadingData}
              onClick={handleClose}
              widthbtn={"100%"}
            >
              Kembali
            </CustomButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const RoleBasedComponent = ({ role, status, produksi }) => {
    if (role === "desainer") {
      if (status === statusBaruMasuk.kode) {
        return (
          <ProgressComponent titleButton="Desain" onSubmit={onSubmitDesain} />
        );
      }
    } else if (role === "admin_produksi") {
      if (status === statusSiapProduksi.kode && !data.group_id) {
        return (
          <ProgressComponent
            titleButton="Produksi"
            onSubmit={onSubmitProduksi}
          />
        );
      }
      if (
        ![
          statusBaruMasuk.kode,
          statusDesain.kode,
          statusSiapProduksi.kode,
          statusSiapKirim.kode,
          statusPengiriman.kode,
          statusSelesai.kode,
          statusRetur.kode,
        ].includes(status) &&
        produksi === user_id
      ) {
        return <ProduksiComponent onSubmit={onSubmitUpdateStatusProduksi} />;
      }
    } else if (role === "customer_service") {
      if (status === statusSiapKirim.kode) {
        return (
          <ProgressComponent titleButton="Kirim" onSubmit={onSubmitKirim} />
        );
      }
    }

    return <CommonComponent />;
  };

  useEffect(() => {
    if (getDataStatus) {
      setListStatus(
        getDataStatus.data.produksi
          .filter((status) => status.status)
          .map((status) => ({
            id: status.id,
            name: status.nama,
            kode: status.kode,
          }))
      );
    }
  }, [getDataStatus]);

  return (
    <BlankDialog
      open={detailOrder.open}
      handleClose={handleClose}
      title={detailOrder.title}
      isLoading={isLoadingSubmit || isLoadingData}
      outsideClose={false}
    >
      <DataForm
        data={data ? data : {}}
        isLoading={isLoadingData}
        withStatus={true}
      />
      <Divider sx={{ marginX: "-1rem" }}>
        <CollapseButton
          onClick={handleProgress}
          checked={openProgress}
          expandLabel="Progress"
          collapseLabel="Progress"
          disabled={isLoadingData}
        />
      </Divider>
      <Collapse in={openProgress}>
        <Box
          marginTop={2}
          sx={{ maxWidth: "100%" }}
          display={"flex"}
          flexDirection={"column-reverse"}
        >
          {!isLoadingData ? (
            data.all_progress ? (
              data.all_progress.map((item, index) => (
                <Grid container padding={1.5} key={index}>
                  <Grid item xs={3} textAlign={"end"} fontSize={"10px"}>
                    <Grid item>
                      {dateFormatter({
                        date: new Date(item.tanggal),
                        withHari: true,
                        withMonth: "simple",
                        withTimes: false,
                        yearLength: "full",
                      })}
                    </Grid>
                    <Grid item>
                      {dateFormatter({
                        date: new Date(item.tanggal),
                        timesOnly: true,
                      })}
                    </Grid>
                  </Grid>
                  <Grid item xs={1} display={"flex"} justifyContent={"center"}>
                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "-0.9rem",
                      }}
                    >
                      <Divider
                        orientation="vertical"
                        sx={{
                          position: "absolute",
                          borderLeft:
                            index !== 0
                              ? "thin dashed rgba(0, 0, 0, 0.12)"
                              : "none",
                          borderRight: "none",
                          zIndex: 5,
                          height: "100%",
                          marginTop: "0.75rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          "&.MuiDivider-root": {
                            "&::before": {
                              border: "none",
                            },
                            "&::after": {
                              border: "none",
                            },
                          },
                        }}
                      />
                      <div
                        style={{
                          marginTop: "0.25rem",
                          zIndex: 6,
                          width: "7px",
                          height: "7px",
                          background:
                            index === data.all_progress.length - 1
                              ? item.status.warna
                              : "#D9D9D9",
                          borderRadius: "100%",
                          opacity: 1,
                        }}
                      >
                        <div
                          style={{
                            zIndex: 7,
                            width: "7px",
                            height: "7px",
                            outlineWidth: `2px`,
                            outlineStyle: "solid",
                            outlineColor:
                              index === data.all_progress.length - 1
                                ? item.status.warna
                                : "rgba(217, 217, 217, 0.01)",
                            borderRadius: "100%",
                            opacity: 0.5,
                          }}
                        />
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={8} textAlign={"start"}>
                    {index === 0 && (
                      <Grid item paddingBottom={0.5}>
                        <Typography
                          fontSize={10}
                          fontWeight={700}
                          color={"#000"}
                        >
                          Check in order !
                        </Typography>
                      </Grid>
                    )}
                    <Grid
                      item
                      marginBottom={0.5}
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <Typography
                        fontSize={10}
                        fontWeight={700}
                        color={"#A3A3A3"}
                      >
                        {index !== 0 ? "Updated to" : "Inserted to"}
                      </Typography>
                      <ChipItems
                        label={item.status.nama}
                        backgroundcolor={item.status.warna}
                        borderradius={"3px"}
                        sx={{
                          fontSize: "10px",
                          margin: "0 5px 0 5px",
                          height: "100%",
                        }}
                      />
                      <Typography
                        fontSize={10}
                        fontWeight={700}
                        color={"#A3A3A3"}
                      >
                        by {item.user.kode_user} : {item.user.nama}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      paddingRight={1}
                      marginBottom={0.5}
                      fontSize={10}
                      color={"#A3A3A3"}
                    >
                      {item.keterangan}
                    </Grid>
                  </Grid>
                </Grid>
              ))
            ) : (
              <div style={{ flex: 1, textAlign: "center" }}>
                Tidak ada progress!
              </div>
            )
          ) : (
            <>
              <Grid container padding={1.5}>
                <Grid item xs={3} textAlign={"end"} fontSize={"10px"}>
                  <Grid item>
                    <Skeleton />
                  </Grid>
                  <Grid item>
                    <Skeleton />
                  </Grid>
                </Grid>
                <Grid item xs={1} display={"flex"} justifyContent={"center"}>
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "-0.9rem",
                    }}
                  >
                    <Divider
                      orientation="vertical"
                      sx={{
                        position: "absolute",
                        // borderLeft: "thin dashed rgba(0, 0, 0, 0.12)",
                        borderLeft: "none",
                        borderRight: "none",
                        zIndex: 5,
                        height: "100%",
                        marginTop: "0.75rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        "&.MuiDivider-root": {
                          "&::before": {
                            border: "none",
                          },
                          "&::after": {
                            border: "none",
                          },
                        },
                      }}
                    />
                    <div
                      style={{
                        marginTop: "0.25rem",
                        zIndex: 6,
                        width: "7px",
                        height: "7px",
                        outlineWidth: `2px`,
                        outlineStyle: "solid",
                        outlineColor: "grey",
                        background: "grey",
                        borderRadius: "100%",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={8} textAlign={"start"}>
                  <Grid item paddingBottom={0.5}>
                    <Skeleton width={"30%"} />
                  </Grid>
                  <Grid
                    item
                    marginBottom={0.5}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Skeleton width={"25%"} />
                  </Grid>
                  <Grid
                    item
                    paddingRight={1}
                    marginBottom={0.5}
                    fontSize={10}
                    color={"#A3A3A3"}
                  >
                    <Skeleton width={"65%"} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container padding={1.5}>
                <Grid item xs={3} textAlign={"end"} fontSize={"10px"}>
                  <Grid item>
                    <Skeleton />
                  </Grid>
                  <Grid item>
                    <Skeleton />
                  </Grid>
                </Grid>
                <Grid item xs={1} display={"flex"} justifyContent={"center"}>
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "-0.9rem",
                    }}
                  >
                    <Divider
                      orientation="vertical"
                      sx={{
                        position: "absolute",
                        borderLeft: "thin dashed rgba(0, 0, 0, 0.12)",
                        borderRight: "none",
                        zIndex: 5,
                        height: "100%",
                        marginTop: "0.75rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        "&.MuiDivider-root": {
                          "&::before": {
                            border: "none",
                          },
                          "&::after": {
                            border: "none",
                          },
                        },
                      }}
                    />
                    <div
                      style={{
                        marginTop: "0.25rem",
                        zIndex: 6,
                        width: "7px",
                        height: "7px",
                        outlineWidth: `2px`,
                        outlineStyle: "solid",
                        outlineColor: "grey",
                        background: "grey",
                        borderRadius: "100%",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={8} textAlign={"start"}>
                  <Grid item paddingBottom={0.5}>
                    <Skeleton width={"30%"} />
                  </Grid>
                  <Grid
                    item
                    marginBottom={0.5}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Skeleton width={"25%"} />
                  </Grid>
                  <Grid
                    item
                    paddingRight={1}
                    marginBottom={0.5}
                    fontSize={10}
                    color={"#A3A3A3"}
                  >
                    <Skeleton width={"65%"} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container padding={1.5}>
                <Grid item xs={3} textAlign={"end"} fontSize={"10px"}>
                  <Grid item>
                    <Skeleton />
                  </Grid>
                  <Grid item>
                    <Skeleton />
                  </Grid>
                </Grid>
                <Grid item xs={1} display={"flex"} justifyContent={"center"}>
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "-0.9rem",
                    }}
                  >
                    <Divider
                      orientation="vertical"
                      sx={{
                        position: "absolute",
                        borderLeft: "thin dashed rgba(0, 0, 0, 0.12)",
                        borderRight: "none",
                        zIndex: 5,
                        height: "100%",
                        marginTop: "0.75rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        "&.MuiDivider-root": {
                          "&::before": {
                            border: "none",
                          },
                          "&::after": {
                            border: "none",
                          },
                        },
                      }}
                    />
                    <div
                      style={{
                        marginTop: "0.25rem",
                        zIndex: 6,
                        width: "7px",
                        height: "7px",
                        outlineWidth: `2px`,
                        outlineStyle: "solid",
                        outlineColor: "grey",
                        background: "grey",
                        borderRadius: "100%",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={8} textAlign={"start"}>
                  <Grid item paddingBottom={0.5}>
                    <Skeleton width={"30%"} />
                  </Grid>
                  <Grid
                    item
                    marginBottom={0.5}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Skeleton width={"25%"} />
                  </Grid>
                  <Grid
                    item
                    paddingRight={1}
                    marginBottom={0.5}
                    fontSize={10}
                    color={"#A3A3A3"}
                  >
                    <Skeleton width={"65%"} />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Collapse>
      <RoleBasedComponent
        role={role}
        status={data?.progress?.status?.kode ?? ""}
        produksi={data?.produksi}
      />
    </BlankDialog>
  );
};

export default DetailOrderScanLayout;
