import { createSlice } from "@reduxjs/toolkit";

const scannerSlice = createSlice({
  name: "scanner",
  initialState: {
    scanOrder: { id: null, open: false, title: null },
    scanGroup: { id: null, open: false, title: null },
    detailOrder: { id: null, open: false, title: null },
    detailGroup: { id: null, open: false, title: null },
  },

  reducers: {
    SCAN_BARCODE_ORDER: (state, action) => {
      state.scanOrder = action.payload;
    },
    SCAN_BARCODE_GROUP: (state, action) => {
      state.scanGroup = action.payload;
    },
    SCAN_DETAIL_ORDER: (state, action) => {
      state.detailOrder = action.payload;
    },
    SCAN_DETAIL_GROUP: (state, action) => {
      state.detailGroup = action.payload;
    },
  },
});

export const {
  SCAN_BARCODE_ORDER,
  SCAN_BARCODE_GROUP,
  SCAN_DETAIL_ORDER,
  SCAN_DETAIL_GROUP,
} = scannerSlice.actions;
export default scannerSlice.reducer;
